@import '../../const';

.admin-page {
    min-height: 100vh;
    width: 100%;
    background-color: #999;
    padding-top: 120px;

    &__wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 50px;

        & form {
            display: flex;
            flex-direction: column;
            width: 200px;
            margin: 0 auto;

            & input {
                background-color: #ccc;
                margin-bottom: 8px;
            }
        }
    }

    &__tours {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__tour {
        height: 100%;
    }

    &__adminka {
        justify-self: center;
        align-self: center;
    }
}

.adminka {
    min-height: 800px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    

    &__tabs {
        height: 100%;
        width: 100%;
        gap: 1px;
        flex: 0 1 150px;
        display: flex;
        flex-direction: column;
    }

    &__tab {
        padding: 25px;
        text-align: center;
        color: #fff;
        border: 2px solid $main-blue;
        background-color: #666;
        border-top-left-radius: 10px;
        cursor: pointer;
    }

    &__content {
        border: 2px solid $main-blue;
        flex: 1 1 600px;
        background-color: #666;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px;
    }

    &__tours-container {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        & select {
            width: 100%;

            &  option {
                width: 100%;
                text-align: center;
            }
        }
    }

    &__tour-title {
        width: 100%;
        color: #464646;
    }

    & .loader {
        position: absolute;
        left: 50%;
        top: 40vh;
        z-index: 25;
    }
}

.tours__select {
    width: 100%;
    padding: 5px;
    font-size: 12px;
    background-color: #333;
    color: #bbb;
}

.tours {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__content {
        margin-top: 10px;
        // height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 120px 1fr;
    }

    &__field-name {
        // flex: 0 0 120px;
        height: 100%;
        color: #ccc;
        font-size: 12px;
    }

    &__field {
        // flex: 1 1 100%;
        color: #ccc;
        font-size: 12px;
        text-align: center;
        height: 25px;
        background-color: #444;
    }

    &__arr-fields {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__arr-field {
        // flex: 1 1 100%;
        color: #ccc;
        font-size: 12px;
        text-align: center;
        height: 25px;
        background-color: #444;
    }

    &__days {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__day {
        display: flex;
        flex-direction: column;

        & span {
            color: white;
            font-size: 12px;
        }
    }


    & button {
        padding: 5px;
        margin-top: 10px;
        font-size: 12px;
        font-weight: 400;
        border-bottom-right-radius: 10px;
    }
}

.tfs {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    gap: 4px;

    & h4 {
        color: #ccc;
        text-align: center;
    }

    &__field {
        width: 100%;
        border: 2px solid #444;
        padding: 2px;
        background-color: #ddd;
        font-weight: 500;
        height: auto;
        overflow: hidden;
        resize: none;
        outline: none;

        &_add {
            width: 90%;
            align-self: flex-end;
        }
    }

    &__main {
        width: 100%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    

    &__other {
        width: 100%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;

    }

    &__row {
        display: flex;
        flex-direction: row;

        & button {
            width: 50px;
        }

        
        &_add {
            width: 90%;
            align-self: flex-end;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;

        & h5 {
            color: #ccc;
            text-align: center;
        }
    }


    &__save-btn {
        width: 100%;
        font-weight: 800;
        font-size: 14px;
    }

    & button {
        background-color: #504545;
        color:#ccc;
    }
    

}