@import '../../const';

.about-page {       

    &:hover {
        .about-page__bg {
            filter: brightness(0.7);
        }
    }

    &__cover {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100vh;
        position: relative;
        @include blockHPadding;
    }

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: top;        
        z-index: -1;
        filter: brightness(0.55);
        transition: filter 0.7s ease;
    }

    &__text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;        
        align-items: center;

        & h1 {
            text-align: center;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
            margin-block-end: 2em;
        }        
    }
    
}

.vk-container {
    @include blockMaxWidth;
    @include blockVPadding;
    margin: 0 auto;

    & h2 {
        text-align: center;
        margin-block-end: 1.5em;
    }
}