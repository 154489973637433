@import '../../const';


@mixin footerPadding {
    padding: 50px 100px;
    @media (max-width: map-get($breakpoints, lg)) {
        padding: 50px 75px;
    }
    @media (max-width: map-get($breakpoints, md)) {
        padding: 40px 35px;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        padding: 30px 25px;
    }
    @media (max-width: map-get($breakpoints, xs)) {
        padding: 20px;
    }
    @media (max-width: map-get($breakpoints, vs)) {
        padding: 15px;
    }    
}

.footer {

    &__privacy {
        @include font-size-adaptive(15px);
        color: white;

        & a {
            color: white;
            text-decoration: underline;
        }
    }


    width: 100%;
    @include footerPadding;
    background-color: $main-blue;
    @include space-btw(row);

    &__logo-container {
        @include space-btw(row);
        align-items: center;

        & img {
            height: 120px;
            width: 120px;
            margin-left: 30px;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 3px;
            @media (max-width: map-get($breakpoints, lg)) {
                @include footer-img-params(90px)
            }
            @media (max-width: map-get($breakpoints, md)) {
                @include footer-img-params(75px);
                padding: 8px;
            }
            @media (max-width: map-get($breakpoints, sm)) {
                @include footer-img-params(50px);
                padding: 5px;
            }
            @media (max-width: map-get($breakpoints, xs)) {
                @include footer-img-params(35px)
            }
            @media (max-width: map-get($breakpoints, vs)) {
                @include footer-img-params(35px)
            }                          
        }

        & .footer__logo:first-child img:hover {
            background-color: rgb(240, 125, 25);
        }
        & .footer__logo:nth-child(2) img:hover {
            background-color: rgb(30, 80, 150);
        }
        & .footer__logo:nth-child(3) img:hover {
            background-color: rgb(0, 150, 220);
        }
    }

    &__contacts {
        @include space-btw(column);
        align-items: flex-end;

        & span {
           color: white;
           @include font-size-adaptive(24px);          
        }
        & > span {
            @media (max-width: 767px) {
                display: none;
           }
        }
    }

    &__icons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;        
    }

   

    #{&}__icon {
        width: 35px;
        @media (max-width: map-get($breakpoints, lg)) {
            margin-left: 23px;
            width: 32px;
        }
        @media (max-width: map-get($breakpoints, md)) {
            width: 28px;
        }
        @media (max-width: map-get($breakpoints, sm)) {
            width: 25px;
        }
        @media (max-width: map-get($breakpoints, xs)) {            
            width: 22px;
        }
        @media (max-width: map-get($breakpoints, vs)) {
            width: 20px;
        }  
    }
}
