@import '../../const';

$main-menu-text-color: #fff;
$submenu-background-color: rgba(255, 255, 255, 0.85);
$main-page-menu-link-color: #fff;
$other-page-menu-link-color: #000;
$main-page-header-background-color: rgba(100, 100, 100, 0.5);
$tour-page-header-background-color: rgba(200, 200, 200, 0.1);

.header {
    padding: 0 80px;
    @media (max-width: map-get($breakpoints, lg)) {
        padding: 0 65px;
    }
    @media (max-width: map-get($breakpoints, md)) {
        padding: 0 35px;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        padding: 0 35px;
    }
    @media (max-width: map-get($breakpoints, xs)) {
        padding: 0 25px;
    }
    @media (max-width: map-get($breakpoints, vs)) {
        padding: 0 15px;
    } 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    &.main-page {
        background-color: $main-page-header-background-color;
    }

    &.tour-page-class {
        background-color: $tour-page-header-background-color
    }

    &.gallery-class {
        background-color: rgba(134, 134, 134, 0.65)
    }

    &__body {
        @include space-btw(row);
        align-items: center;
        height: map-get($header-height, vl);
        @media (max-width: map-get($breakpoints, lg)) {
            height: map-get($header-height, lg);
        }
        @media (max-width: map-get($breakpoints, md)) {
            height: map-get($header-height, md);
        }
        @media (max-width: map-get($breakpoints, sm)) {
            height: map-get($header-height, sm);
        }
        @media (max-width: map-get($breakpoints, xs)) {
            height: map-get($header-height, xs);
        }
        @media (max-width: map-get($breakpoints, vs)) {
            height: map-get($header-height, vs);
        } 
    }

    &__home {
        // display: none;
        order: -1;

        @media (max-width: map-get($breakpoints, sm)) {
            display: block;
            order: 0;
        }

        width: 35px;
        & img {
            width: 100%;
        }

        &.other-page {
            img {
                filter: invert(1);
            }
        }
    }

    &__menu {
        width: 100%;
        height: 100%;

        @media (max-width: map-get($breakpoints, sm)) {
            display: none;
        }
    }

    & a {
        display: block;
        @include font-size-adaptive(18px);
        font-weight: 600;
        text-transform: uppercase;
        color: $main-menu-text-color;
        text-decoration: none;
    }

    &__contacts {
        width: 250px;
        height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        @media (max-width: map-get($breakpoints, md)) {
            width: 200px;
        }

        & a {
            @include font-size-adaptive(19px);
            font-weight: 600;

            &:first-child{
                font-family: 'arial';
                font-style: italic;
            }
        }

        &.main-page {
            a {
                color: $main-page-menu-link-color;
            }
            .header__icon {
                filter: invert(0);
            }
        }
        &.other-page {
            a {
                color: $other-page-menu-link-color;
            }
            .header__icon {
                filter: invert(1);
            }
        }
        &.gallery-class {
            a {
                color: $other-page-menu-link-color;
            }
            .header__icon {
                filter: invert(1);
            }
        }
    }

    &__icons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        @media (max-width: map-get($breakpoints, sm)){
            display: none;
        }
    }

    &__icon {
        width: 35px;
        @media (max-width: map-get($breakpoints, lg)){
            width: 30px;
        }
        @media (max-width: map-get($breakpoints, md)){
            width: 22px;
        }
    }

    &__burger {
        display: block;
        cursor: pointer;

        @media (max-width: map-get($breakpoints, sm)) {
            display: block;
            position: relative;
            width: 30px;
            height: 20px;
            z-index: 11;

            & span {                
                position: absolute;
                left: 0;
                background-color: #ffffff;
                width: 100%;
                height: 2px;
                top: 9px;
                transition: all 0.3s ease 0s;
            }
            &:before, &:after {
                content: '';
                background-color: #ffffff;
                position: absolute;
                width: 100%;
                height: 2px;
                left: 0;
                transition: all 0.3s ease;
            }            
            &:before {
                top: 0;
            }
            &:after {
                bottom: 0;
            }
        }
       

        &.active {            
            &:before {
                transform: rotate(45deg);
                top: 9px;
                background-color: #000000;
            }
            &:after {
                transform: rotate(-45deg);
                bottom: 9px;
                background-color: #000000;
            }
            & span{
                transform: scale(0);
            }
        }
        &.other-page {
            filter: invert(1);
            &:before {
                background-color: #fff;
            }
            &:after {
                background-color: #fff;
            }
        }
    }

}

.menu {
    &__list {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        z-index: 3;

        & > li {
            list-style: none;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            flex-grow: 1;

            &:hover .sub-menu {
                opacity: 1;
                visibility: visible;   
                pointer-events: all;
            }
        }
        
        @media (max-width: map-get($breakpoints, sm)) {
            display: none;
        }
    }   
    &__link {
        position: relative;
        padding-bottom: 2px;
        overflow: hidden;      

        &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 50%;
            width: 0%;
            height: 2px;            
            transition-property: left, width;
            transition-duration: 0.3s;
            transition-timing-function: ease;            
        }

        &.main-page {
            color: $main-page-menu-link-color;
            &::after {
                background-color: $main-page-menu-link-color;
            }
        }
        &.other-page {
            color: $other-page-menu-link-color;
            &::after {
                background-color: $other-page-menu-link-color;
            }
        }
        
        &.tour-page-class {
            color: $main-page-menu-link-color;
            &::after {
                background-color: $main-page-menu-link-color;
            }
        }

        &.gallery-class {
            color: $other-page-menu-link-color;
            &::after {
                background-color: $other-page-menu-link-color;
            }
        }

        &:hover::after{
            width: 100%;
            left: 0;
        }

        &.active {
            color: $main-blue;
            &::after {
                background-color: $main-blue;
            }
        }
    }
}

.sub-menu {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.6s ease 0s;
    position: absolute;
    left: 0;
    background-color: $submenu-background-color;
    width: 270px;
    min-height: 100%;
    top: calc(map-get($header-height, vl) * 0.75);
    @media (max-width: map-get($breakpoints, lg)) {
        top: calc(map-get($header-height, lg) * 0.75);
    }
    @media (max-width: map-get($breakpoints, md)) {
        top: calc(map-get($header-height, md) * 0.75);
    }

    &__link {
        position: relative;
        color: #000000;
        padding: 1.5em;
        transition: background-color 0.8s ease;

        & a {
            color: #000000;
            font-weight: 450;
            &:hover {
                color: $main-blue;
            }
        }

        &:hover {
            background-color: lighten($color: $submenu-background-color, $amount: 30%);           
        }
    }

}


.burger-menu {
    overflow: auto;
    width: 300px;
    z-index: 10;
    background-color: $submenu-background-color;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    transition: left 0.5s ease;

    &.active {
        left: 0;
    }

    & > ul {
        line-height: 1.6em;
        @media (max-width: map-get($breakpoints, sm)) {
            margin-top: map-get($header-height, sm);
            margin-bottom: map-get($header-height, sm);
             & > li > a {padding-left: 35px;}
             & > li > ul > li > a {padding-left: 55px;}
        }
        @media (max-width: map-get($breakpoints, xs)) {
            margin-top: map-get($header-height, xs);
            margin-bottom: map-get($header-height, xs);
            & > li > a {padding-left: 25px;}
            & > li > ul > li > a {padding-left: 54px;}
        }
        @media (max-width: map-get($breakpoints, vs)) {
            margin-top: map-get($header-height, vs);
            margin-bottom: map-get($header-height, vs);
            & > li > a {padding-left: 15px;}
            & > li > ul > li > a {padding-left: 35px;}
        }
        & > li {
            margin-top: 1em;
        }
        li {
            & > ul {
                
                & li a {
                    text-transform: none;
                    font-weight: 400;
                }
            }
        }
    }
    
    a {
        color: #000;
        @include font-size-adaptive(24px);
        &:hover {
            background-color: lighten($color: $main-blue, $amount: 45%);
            color: $main-blue;
        }    
    }
}
